import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { HTTP, Response } from 'service';
import { TooltipIcon } from './elements';

import './NumberOfEmployeesPopup.scss';

const NumberOfEmployeesPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [directEmployees, setDirectEmployees] = useState(0);
    const [labourOnly, setLabourOnly] = useState(0);
    const [bonaFideSubContractors, setSubContractors] = useState(0);
    const [companyResponse, setCompanyResponse] = useState({});

    const rootResponse = useSelector((state: any) => state.root.response);

    const loadCompany = () => {
        HTTP.get(Response.getLink(rootResponse, 'company')).then((response) => {
            if (response) {
                const { data } = response;
                const { employeeCountsLastUpdated } = data;

                setCompanyResponse(data);

                if (employeeCountsLastUpdated) {
                    let updateDate = moment
                        .utc(employeeCountsLastUpdated)
                        .add(6, 'months')
                        .local();

                    setIsOpen(updateDate <= moment());
                } else {
                    setIsOpen(true);
                }
            }
        });
    };

    useEffect(() => {
        loadCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnClose = () => {
        setIsOpen(false);
    };

    const getEffectiveEmployeeCount = () => directEmployees + labourOnly;

    const handleSubmit = () => {
        HTTP.put(Response.getLink(companyResponse, 'edit'), {
            ...companyResponse,
            labourOnly,
            directEmployees,
            bonaFideSubContractors,
            organisationSize: directEmployees + labourOnly,
            employeeCountsLastUpdated: new Date(),
        }).then((response) => {
            if (response) {
                toast.success('Employee counts updated');
                handleOnClose();

                return true;
            }

            toast.error('Could not update employee counts');
            return false;
        });
    };

    return (
        <Dialog
            className='employee-counts-popup'
            onClose={handleOnClose}
            title="Please Confirm your number of Employees"
            autoFocus={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={isOpen}
        >
            <form onSubmit={handleSubmit}>
                <div className="employee-counts-dialog">
                    <p className="guidance">
                        This information is required for reporting into SSIP.
                    </p>
                    <FormGroup
                        className="form-fill required"
                        label="Number of Direct Employees"
                        labelFor="directEmployees"
                        inline
                    >
                        <input
                            id="directEmployees"
                            placeholder="0"
                            type="number"
                            min="0"
                            onChange={(e) =>
                                setDirectEmployees(Number(e.target.value))
                            }
                            required
                        />
                        <TooltipIcon
                            wrappingClass="employee-counts-tooltip-icon"
                            text={`Direct employees are workers hired by a company and paid a salary or hourly wages through the company's payroll.`}
                            icon="info-circle"
                        />
                    </FormGroup>
                    <FormGroup
                        className="form-fill required"
                        label="Number of Bona Fide Sub Contractors"
                        labelFor="bonaFideSubContractors"
                        inline
                    >
                        <input
                            id="bonaFideSubContractors"
                            placeholder="0"
                            type="number"
                            min="0"
                            required
                            onChange={(e) =>
                                setSubContractors(Number(e.target.value))
                            }
                        />
                        <TooltipIcon
                            wrappingClass="employee-counts-tooltip-icon"
                            text={`Contractors who work without direction, hold their own insurance and provide their own materials and tools.`}
                            icon="info-circle"
                        />
                    </FormGroup>
                    <FormGroup
                        className="form-fill required"
                        label="Number of Labour Only"
                        labelFor="labourOnly"
                        inline
                    >
                        <input
                            id="labourOnly"
                            placeholder="0"
                            type="number"
                            min="0"
                            onChange={(e) =>
                                setLabourOnly(Number(e.target.value))
                            }
                            required
                        />
                        <TooltipIcon
                            wrappingClass="employee-counts-tooltip-icon"
                            text={`According to UK law a labour only subcontractor is considered an employee. They are working under your supervision and using material, equipment, and tools that you have provided.`}
                            icon="info-circle"
                        />
                    </FormGroup>
                    <FormGroup
                        className="form-fill"
                        label="Number of Effective Employees"
                        labelFor="organisationSize"
                        inline
                    >
                        <input
                            id="organisationSize"
                            placeholder="Number of effective employees"
                            value={getEffectiveEmployeeCount()}
                            disabled
                        />
                        <TooltipIcon
                            wrappingClass="employee-counts-tooltip-icon"
                            text={`The total number of direct employees, full-time, part-time, permanent and temporary,
                            as part of your PAYE scheme. Also to include the total number of labour-only
                            subcontractors. Labour-only subcontractors are typically considered third-party
                            works who have worked under your supervision, using materials, tools and equipment
                            that you have provided, and would typically be covered by your insurance policies.`}
                            icon="info-circle"
                        />
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button type="submit" intent={Intent.SUCCESS}>
                                Update
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default NumberOfEmployeesPopup;
