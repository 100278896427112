import { AxiosResponse } from 'axios';

import {
    Campaign,
    CampaignInvitee,
    CampaignInviteeListFilter,
    CampaignListFilter,
    CampaignRevenueByMonth,
    CampaignUpdate,
    CampaignUpdatesListFilter,
} from 'interface/Client/Campaign';

import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { FlatObject, ListCollection, ListFilter } from 'interface';
import { EmailTemplateData } from 'interface/Email';
import HTTP from 'service/HTTP';
import Response from 'service/Response';

class CampaignService {
    static loadList = async (
        filter?: CampaignListFilter
    ): Promise<ListCollection<Campaign>> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/clients/campaigns',
            filter ? filter : {},
            {},
            'Unable to load clients campaigns'
        );

        if (response) {
            return {
                count: response.data.count,
                data: response.data.campaigns,
                limit: response.data.limit,
                page: response.data.page,
            } as ListCollection<Campaign>;
        }

        return {} as ListCollection<Campaign>;
    };

    static getCampaignsDropdown = async () => {
        const options = [NONE_SELECTED_OPTION];
        const response = await this.loadList({
            limit: 999,
            order: 'name',
            page: 1,
            hasChases: true,
            datagroup: 'search',
        });

        const campaigns = response?.data;

        if (campaigns) {
            campaigns.forEach((campaign: any) => {
                options.push({
                    label: campaign.name,
                    value: campaign.id,
                });
            });
        }

        return options;
    };

    static downloadCampaigns = async (
        filter?: CampaignListFilter
    ): Promise<void> => {
        await HTTP.stream(
            '/clients/campaigns/download',
            {},
            filter ? filter : {}
        );

        return;
    };

    static downloadCampaignInviteeList = async (
        campaignId: string,
        filter?: CampaignInviteeListFilter
    ): Promise<void> => {
        await HTTP.stream(
            `/clients/campaigns/${campaignId}/invitees/download`,
            {},
            filter ? filter : {}
        );

        return;
    };

    static downloadInviteeUploadTemplate = async (
        campaignId: string
    ): Promise<void> => {
        await HTTP.stream(
            `/clients/campaigns/${campaignId}/download-import-template`,
            {},
            {}
        );

        return;
    };

    static processInviteeUploadTemplate = async (
        campaignId: string,
        data: FlatObject,
        errorFn: Function
    ): Promise<AxiosResponse | undefined> => {
        return HTTP.action(
            'post',
            `/clients/campaigns/${campaignId}/upload-import-template`,
            data,
            {},
            'Template could not be processed',
            errorFn
        );
    };

    static downloadPurchaseUpdatesList = async (
        filter?: CampaignUpdatesListFilter
    ): Promise<void> => {
        await HTTP.stream(
            `/clients/campaigns/updates-download`,
            {},
            filter ? filter : {}
        );

        return;
    };

    static downloadCampaignInviteeTrackerList = async (
        campaignId?: string,
        filter?: CampaignInviteeListFilter
    ): Promise<void> => {
        const url = campaignId
            ? `/clients/campaigns/${campaignId}/invitees-tracker/download`
            : `/clients/campaigns/invitees-tracker/download`;
        await HTTP.stream(url, {}, filter ? filter : {});

        return;
    };

    static loadInviteeList = async (
        campaign?: Campaign,
        filter?: CampaignInviteeListFilter
    ): Promise<ListCollection<CampaignInvitee>> => {
        let url = '/client/campaigns/invitees';

        if (campaign) {
            url = Response.getLink(campaign, 'invitees');
        }

        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            filter ? filter : {},
            {},
            'Unable to load clients campaign invitees',
            (error: any) => {
                console.warn(error);
            }
        );

        if (response) {
            return {
                count: response.data.count,
                data: response.data.invitees,
                limit: response.data.limit,
                page: response.data.page,
            } as ListCollection<CampaignInvitee>;
        }

        return {} as ListCollection<CampaignInvitee>;
    };

    static loadRevenueByMonth = async (
        campaign: Campaign,
        filter?: ListFilter
    ): Promise<ListCollection<CampaignRevenueByMonth>> => {
        let url = Response.getLink(campaign, 'revenue-by-month');

        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            filter ? filter : {},
            {},
            'Unable to load campaign revenue by month',
            (error: any) => {
                console.warn(error);
            }
        );

        if (response) {
            return {
                count: response.data.count,
                data: response.data.revenues,
                limit: response.data.limit,
                page: response.data.page,
            } as ListCollection<CampaignRevenueByMonth>;
        }

        return {} as ListCollection<CampaignRevenueByMonth>;
    };

    static loadEvents = async (
        url: string,
        filter?: ListFilter
    ): Promise<ListCollection<any>> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            filter ? filter : {},
            {},
            'Unable to load clients campaigns events'
        );

        if (response) {
            return {
                count: response.data.count,
                data: response.data.events,
                limit: response.data.limit,
                page: response.data.page,
            } as ListCollection<object>;
        }

        return {} as ListCollection<object>;
    };

    static loadEmailTemplates = async (
        url: string,
        filter?: ListFilter
    ): Promise<ListCollection<any>> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            filter ? filter : {},
            {},
            'Unable to load clients campaigns email templates'
        );

        if (response) {
            return {
                count: response.data.count,
                data: response.data.emailTemplates,
                limit: response.data.limit,
                page: response.data.page,
            } as ListCollection<object>;
        }

        return {} as ListCollection<object>;
    };

    static sendEmail = async (
        template: EmailTemplateData,
        isSendAll: boolean,
        invitees?: CampaignInvitee[],
        filter?: CampaignInviteeListFilter,
    ): Promise<any> => {
        const res = await HTTP.action(
            'post',
            Response.getLink(template, 'sendEmail'),
            {
                ...filter,
                _links: {
                    invitees: invitees?.map(
                        (invitee) => invitee._links['self']
                    ),
                },
                isSendAll: isSendAll,
            },
            {},
            'Could not send email'
        );
        return res;
    };

    static loadById = async (id: string): Promise<Campaign> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/clients/campaigns/' + id,
            {},
            {},
            'Unable to load campaign'
        );

        if (response) {
            return response.data as Campaign;
        }

        return {} as Campaign;
    };

    static loadByInviteeId = async (
        campaignId: string,
        inviteeId: string
    ): Promise<CampaignInvitee> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/clients/campaigns/${campaignId}/invitees/${inviteeId}`,
            {},
            {},
            'Unable to load clients campaign invitee',
            (error: any) => {
                console.warn(error);
            }
        );

        if (response) {
            return response.data as CampaignInvitee;
        }

        return {} as CampaignInvitee;
    };

    static addInvitee = async (
        campaign: Campaign,
        form: any,
        errorCallback: any = void 0
    ): Promise<CampaignInvitee | void> => {
        const res = await HTTP.action(
            'post',
            Response.getLink(campaign, 'add-invitee'),
            form,
            {},
            'Could not add new Invitee',
            (error: any) => {
                errorCallback && errorCallback(error);
                console.warn(error);
            }
        );

        return res ? res.data : void 0;
    };

    static addExistingInvitee = async (
        campaign: Campaign,
        form: any
    ): Promise<CampaignInvitee | void> => {
        const res = await HTTP.action(
            'post',
            Response.getLink(campaign, 'add-existing-invitee'),
            form,
            {},
            'Could not add new Invitee',
            (error: any) => {
                console.warn(error);
            }
        );

        return res ? res.data : void 0;
    };

    static editInvitee = async (
        form: CampaignInvitee
    ): Promise<CampaignInvitee | void> => {
        const res = await HTTP.action(
            'put',
            Response.getLink(form, 'edit'),
            form,
            {},
            'Could not update Invitee',
            HTTP.handleFormErrors
        );

        return res ? res.data : void 0;
    };

    static verifyReferralCode = async (
        referralCode: string
    ): Promise<boolean> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/clients/campaigns/verify-referral-code',
            { referralCode },
            {},
            'Unable to verify Referral Code'
        );

        if (response) {
            return response.data;
        }

        return false;
    };

    static assignInvitee = async (
        invitee: CampaignInvitee,
        form: any
    ): Promise<CampaignInvitee | void> => {
        const res = await HTTP.action(
            'patch',
            Response.getLink(invitee, 'assign'),
            form,
            {},
            'Could not assign company to Invitee',
            HTTP.handleFormErrors
        );

        return res ? res.data : void 0;
    };

    static updateInviteeStatus = async (
        invitee: CampaignInvitee,
        form: any
    ): Promise<CampaignInvitee | void> => {
        const res = await HTTP.action(
            'put',
            Response.getLink(invitee, 'update-status'),
            form,
            {},
            `Could not update Status for ${invitee.inviteeName}`,
            (error: any) => {
                console.warn(error);
            }
        );

        return res ? res.data : void 0;
    };

    static getSpCategoryOptions = async (
        campaign: Campaign
    ): Promise<any[]> => {
        const options = [NONE_SELECTED_OPTION];
        const response = await HTTP.action(
            'get',
            Response.getLink(campaign, 'sp-category-names'),
            {},
            {},
            `Could not get Service Provider Category names`,
            (error: any) => {
                console.warn(error);
            }
        );

        response?.data?.forEach((name: string) => {
            options.push({
                label: name,
                value: name,
            });
        });
        return Promise.resolve(options);
    };

    static loadUpdatesList = async (
        filter: CampaignUpdatesListFilter,
        purchasesOnly: boolean = false
    ): Promise<ListCollection<CampaignUpdate>> => {
        filter.purchasesOnly = purchasesOnly;

        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/clients/campaigns/updates`,
            filter ? filter : {},
            {},
            'Unable to load client campaigns updates'
        );

        if (response) {
            return {
                count: response.data.count,
                data: response.data.updates,
                limit: response.data.limit,
                page: response.data.page,
            } as ListCollection<CampaignUpdate>;
        }

        return {} as ListCollection<CampaignUpdate>;
    };

    static loadInviteeUpdatesList = async (
        inviteId: string,
        filter: CampaignUpdatesListFilter
    ): Promise<ListCollection<CampaignUpdate>> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/clients/campaigns/` + inviteId + `/updates`,
            filter ? filter : {},
            {},
            'Unable to load client campaigns updates'
        );

        if (response) {
            return {
                count: response.data.count,
                data: response.data.updates,
                limit: response.data.limit,
                page: response.data.page,
            } as ListCollection<CampaignUpdate>;
        }

        return {} as ListCollection<CampaignUpdate>;
    };
}

export default CampaignService;
