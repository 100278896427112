import { FormEvent, useEffect, useState } from 'react';
import { Button, FormGroup, H1, InputGroup, Switch } from "@blueprintjs/core";
import { isMobile } from "react-device-detect";
import { DateInput } from "@blueprintjs/datetime";
import { DateTime } from "luxon";
import moment from 'moment';

import { DISCOUNT_STATUS_OPTIONS, DiscountData } from "interface/Discount";
import { AssessmentTypeData, AssessmentTypeFilter, AssessmentTypeListData } from "interface/AssessmentType";
import { CompanyData, CompanyFilter, CompanyListData } from "interface/Company";
import { globalDebounce } from 'helpers/helpers';
import { Routing } from 'service';
import AssessmentTypeService from 'service/AssessmentType/AssessmentTypeService';
import DiscountService from "service/Discount/DiscountService";
import CompanyService from "service/Company/CompanyService";
import { ButtonLink, HTMLSelect, Icon, InputElementIcon, MultiSelectRenderer } from "components/elements";
import { Loading } from "components/elements/wrappers";
import PositiveNumericInput from "components/elements/PositiveNumericInput";

const DiscountAddEdit = (props: any) =>
{
    const { history, match, discountResponse, reloadData } = props;
    const [ isLoading, setIsLoading ] = useState(true);
    const [ assessmentTypes, setAssessmentTypes ] = useState<Partial<AssessmentTypeData>[]>();
    const [ assessmentTypeFilter, setAssessmentTypeFilter ] = useState<Partial<AssessmentTypeFilter>>(AssessmentTypeService.createAssessmentTypeFilter());
    const [ serviceProviders, setServiceProviders ] = useState<CompanyData[]>();
    const [ serviceProviderFilter, setServiceProviderFilter ] = useState<Partial<CompanyFilter>>(CompanyService.createCompanyFilter());
    const [ form, setForm ] = useState<DiscountData>(DiscountService.createDiscount());
    const [ isEdit, setIsEdit ] = useState<boolean>(false);

    const searchAssessmentTypes = (name?: string) => {
        const filter = { ...assessmentTypeFilter, name: name };
        !name && delete filter.name;
        setAssessmentTypeFilter(filter);
    }

    const searchServiceProviders = (name?: string) => {
        const filter = { ...serviceProviderFilter, registeredCompanyName: name };
        !name && delete filter.registeredCompanyName;
        setServiceProviderFilter(filter);
    }

    const load = () =>
    {
        (async () => {
            setIsLoading(true);

            if (discountResponse) {
                setIsEdit(true);
                setForm({
                    ...form,
                    ...discountResponse,
                    startDate: new Date(discountResponse.startDate),
                    expiryDate: new Date(discountResponse.expiryDate)
                });
            }

            loadAssessmentTypes(loadServiceProviders);
        })();
    }

    const loadAssessmentTypes = (callback?: Function) => {
        globalDebounce(async () => {
            const assessmentTypeList : AssessmentTypeListData = await AssessmentTypeService.loadList(assessmentTypeFilter);
            setAssessmentTypes(assessmentTypeList.assessmentTypes);
            callback && callback();
        }, 'DiscountAssessmentTypes', 250);
    }

    const loadServiceProviders = (callback?: Function) => {
        globalDebounce(async () => {
            const serviceProviderList : CompanyListData = await CompanyService.loadList(serviceProviderFilter);
            setServiceProviders(serviceProviderList.companies);
            setIsLoading(false);
            callback && callback();
        }, 'DiscountServiceProviders', 250);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadAssessmentTypes, [assessmentTypeFilter]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadServiceProviders, [serviceProviderFilter]);

    return <div className="discountAddEdit">
        <H1>
            { isEdit ? "Edit Discount" : "Add Discount" }
        </H1>

        <Loading isLoading={isLoading}>
            <form className="AddEdit" onSubmit={(e: FormEvent<HTMLFormElement>) => DiscountService.handleSubmit(e, form, history, match, reloadData)}>
                <FormGroup label="Code"
                    inline={true}
                    className="form-fill required"
                    labelFor="Discount Code">
                    <InputGroup id="code"
                        placeholder="Discount Code"
                        minLength={ 5 }
                        maxLength={ 32 }
                        value={ form.code }
                        onChange={ e => {setForm({...form, code: e.target.value});} }
                        required />
                </FormGroup>

                <FormGroup label="Start Date"
                           inline={ !isMobile }
                           className="form-fill choice required">
                    <DateInput
                        parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                        formatDate={date => DateTime.fromJSDate(date).toLocaleString()}
                        popoverProps={{ position: "bottom", fill: true }}
                        inputProps={{
                            fill: true,
                            id: 'startDate',
                            rightElement: InputElementIcon('Select a date...', 'calendar-alt')
                        }}
                        onChange={(newDate) => {
                            newDate = new Date(+newDate + 1000);
                            setForm({
                                ...form,
                                startDate: newDate
                            });
                        }}
                        value={ form.startDate } />
                </FormGroup>

                <FormGroup label="Expiry Date"
                    inline={ !isMobile }
                    className="form-fill choice required">
                    <DateInput
                        parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                        formatDate={ date => DateTime.fromJSDate(date).toLocaleString() }
                        popoverProps={ {position: "bottom", fill: true} }
                        inputProps={ {
                            fill: true,
                            id: 'expiryDate',
                            rightElement: InputElementIcon('Select a date...', 'calendar-alt')
                        } }
                        onChange={ (newDate) => {
                            newDate = new Date(+newDate + 1000);
                            setForm({
                                ...form,
                                expiryDate: newDate
                            });
                        } }
                        value={ form.expiryDate } />
                </FormGroup>

                <FormGroup label={ "Assessments" }
                    labelFor={ "assessments" }
                    className={ "bp3-inline form-fill multi-select-container" }>
                    <MultiSelectRenderer id={ "assessment(s)" }
                         onRemoveItem={ (items: any) => {
                             setForm({...form, assessments: items})
                         } }
                         onItemSelect={ (items: any) => {
                             searchAssessmentTypes();
                             setForm({...form, assessments: items})
                         } }
                         file={ true }
                         items={ assessmentTypes }
                         selectedItems={ form.assessments }
                         valueProperty={ "name" }
                         placeholder={ "Select Assessments" }
                         onKeyUp={ (e: any) => { searchAssessmentTypes(e.target.value) } }
                    />
                </FormGroup>

                <FormGroup label="Limited to Specific Service Provider(s)"
                    inline={true}
                    className="form-fill"
                    labelFor="isLimitedToSpecificServiceProviders">
                    <Switch id="isLimitedToSpecificServiceProviders"
                        checked={ form.isLimitedToSpecificServiceProviders }
                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            setForm({...form, isLimitedToSpecificServiceProviders: e.target.checked });
                        } } />
                </FormGroup>

                {
                    form.isLimitedToSpecificServiceProviders &&
                        <FormGroup label={ "Service Providers" }
                            labelFor={ "serviceProviders" }
                            className={ "bp3-inline form-fill multi-select-container" }>
                            <MultiSelectRenderer id={ "Service Provider(s)" }
                                onRemoveItem={ (items: any) => {
                                    setForm({...form, serviceProviders: items})
                                } }
                                onItemSelect={ (items: any) => {
                                    searchServiceProviders();
                                    setForm({...form, serviceProviders: items})
                                } }
                                file={ true }
                                items={ serviceProviders }
                                selectedItems={ form.serviceProviders }
                                valueProperty={ "registeredCompanyName" }
                                placeholder={ "Select Service Provider" }
                                onKeyUp={ (e: any) => { searchServiceProviders(e.target.value) } }
                            />
                        </FormGroup>
                }

                <FormGroup label="Discount"
                    inline={true}
                    className="form-fill required"
                    labelFor="discount">
                    <PositiveNumericInput id="discount"
                        fill={ true }
                        min={ 0 }
                        max={ 100 }
                        value={ form.discount }
                        onUpdate={(numericValue: number) => setForm({...form, discount: numericValue })}
                        required />
                </FormGroup>

                <FormGroup label="Limited usage"
                           inline={ true }
                           className="form-fill"
                           labelFor="limitedUsage">
                    <Switch id="limitedUsage"
                            checked={ form.limitedUsage }
                            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                                setForm({...form, limitedUsage: e.target.checked });
                            } } />
                </FormGroup>

                {
                    form.limitedUsage &&
                        <FormGroup label="Usage Limit"
                            inline={ true }
                            className="form-fill required"
                            labelFor="usageLimit">
                            <PositiveNumericInput id="usageLimit"
                                fill={ true }
                                min={ 0 }
                                max={ 100 }
                                value={ form.usageLimit }
                                onUpdate={(numericValue: number) => setForm({...form, usageLimit: numericValue })}
                                required />
                        </FormGroup>
                }

                <FormGroup label="Status"
                    inline={ true }
                    className="form-fill"
                    labelFor="status">
                    <HTMLSelect id="status"
                        fill={ true }
                        defaultValue={ form.status }
                        onChange={ (e: any) => {
                            setForm({...form, status: e.target.value})
                        } }
                        options={ DISCOUNT_STATUS_OPTIONS }/>
                </FormGroup>

                <FormGroup>
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to={Routing.getRouteUpOneLevel(history, match, null)}>
                        <Icon icon="ban" />
                        Cancel
                    </ButtonLink>
                    <Button type="submit"
                            intent="primary"
                            className="float-right">
                        <Icon icon="paper-plane" />
                        { isEdit ? 'Update' : 'Create' }
                    </Button>
                </FormGroup>
            </form>
        </Loading>
    </div>
}

export default DiscountAddEdit;
