import { NONE_SELECTED_OPTION } from 'constants/none-selected';

const ACCLAIM                = {label: 'Acclaim Accreditation', value: 'acclaim-accreditation'};
const ACDC                   = {label: 'ACDC Register', value: 'acdc-register'};
const ACHILLES               = {label: 'Achilles Building Confidence', value: 'achilles-building-confidence'};
const ACM                    = {label: 'ACM', value: 'acm'};
const ADVANCEDCERT           = {label: 'Advanced Certification', value: 'advanced-certification'};
const AJAEUROPE              = {label: 'AJA Europe', value: 'aja-europe'};
const ALCUMUS                = {label: 'Alcumus ISOQAR', value: 'alcumus-isoqar'};
const ALCUMUS_SAFECONTRACTOR = {label: 'Alcumus SafeContractor', value: 'alcumus-safecontractor'};
const ALTIUS                 = {label: 'Altius VA Ltd CDM Comply', value: 'altium-va-ltd-cdm-comply'};
const ARB                    = {label: 'ARB Approved Contractor Scheme', value: 'arb-approved-contractor-scheme'};
const APS                    = {label: 'Association for Project Safety (APS)', value: 'aps-registered-practice-membership-scheme'};
const ATLASTCERT             = {label: 'Atlast Certification', value: 'atlast-certification'};
const AVETTA                 = {label: 'Avetta', value: 'avetta'};
const BMTRADA                = {label: 'BM TRADA Certification', value: 'bm-trada-certification'};
const BSI                    = {label: 'British Standards Institution (BSI)', value: 'british-standards-institution'};
const BVC                    = {label: 'Bureau Veritas Certification UK', value: 'bureau-veritas-certification'};
const CFA                    = {label: 'Centre for Assessment', value: 'centre-for-assessment'};
const CEF                    = {label: 'Construction Federation Service Safe-T-Cert - Construction Employers', value: 'cef-safe-t-cert'};
const CIF                    = {label: 'Construction Federation Service Safe-T-Cert - Industry Employers', value: 'cif-safe-t-cert'};
const CHAS                   = {label: 'Contractors Health and Safety Assessment Scheme (CHAS)', value: 'chas'};
const CQMS                   = {label: 'CQMS Safety Scheme', value: 'cqms-safety-scheme'};
const DWHS                   = {label: 'D W Health & Safety Contractor Competency Scheme', value: 'dwhs-contractors-competency-scheme'};
const DNV                    = {label: 'DNV Business Assurance UK Limited', value: 'dnv'};
const EUROSAFE               = {label: 'Eurosafe - CDM Competent Scheme', value: 'eurosafe-cdm'};
const FASET                  = {label: 'FASET Membership Audit', value: 'faset-membership-audit'};
const FOREFRONT              = {label: 'Forefront Certification Limited', value: 'forefront-certification'};
const GREENLIGHT             = {label: 'Greenlight Safety Assessment Scheme Ltd', value: 'greenlight-safety-assessment-scheme'};
const HIRE                   = {label: 'Hire Association Europe', value: 'hire-association-europe'};
const IASSESSOR              = {label: 'I Assessor', value: 'i-assessor'};
const IPAF                   = {label: 'International Powered Access Federation (IPAF)', value: 'international-powered-access-federation'};
const INTERTEK               = {label: 'Intertek Certification', value: 'intertek-certification'};
const LRQA                   = {label: 'LRQA SSIP Registration Scheme', value: 'lrqa-ssip-registration-scheme'};
const MSLPS                  = {label: 'MSL Safepartner', value: 'mslpc-safepartner-scheme'};
const NASC                   = {label: 'National Access & Scaffolding Confederation (NASC)', value: 'national-access-scaffolding-confederation'};
const NFDC                   = {label: 'National Federation of Demolition Contractors', value: 'national-federation-demolition-contractors'};
const NQA                    = {label: 'NQA', value: 'nqa'};
const NSAI                   = {label: 'NSAI', value: 'nsai'};
const OCEAN                  = {label: 'Ocean Certification', value: 'ocean-certification'};
const PQS                    = {label: 'Peers Quality Assurance Ltd', value: 'peers-quality-assurance'};
const PREPQS                 = {label: 'PQS Pre Qualification Scheme', value: 'pre-peers-quality-assurance'};
const PS                     = {label: 'Principal Approved Supplier Scheme - PASS', value: 'ps-pass'};
const SMAS                   = {label: 'Safety Management Advisory Services (SMAS)', value: 'smas-worksafe-scheme'};
const SGS                    = {label: 'SGS Systems & Services Certification Services', value: 'sgs-systems-services'};
const SOCOTEC                = {label: 'SOCOTEC Certification UK', value: 'socotec'};
const SCCS                   = {label: 'Steel Construction Certification Scheme', value: 'steel-construction-scheme'};
const SCSL                   = {label: 'System Certification Services Ltd', value: 'system-certification-services'};
const TBAB                   = {label: 'The British Assessment Bureau', value: 'the-british-assessment-bureau'};
const URS                    = {label: 'URS Certification', value: 'urs'};
const YUV                    = {label: 'YUV UK Ltd', value: 'yuv-uk'};

const SSIP_SCHEME_OPERATOR_OPTIONS = [
    NONE_SELECTED_OPTION,
    ACCLAIM,
    ACDC,
    ACHILLES,
    ACM,
    ADVANCEDCERT,
    AJAEUROPE,
    ALCUMUS,
    ALCUMUS_SAFECONTRACTOR,
    ALTIUS,
    ARB,
    APS,
    ATLASTCERT,
    AVETTA,
    BMTRADA,
    BSI,
    BVC,
    CFA,
    CEF,
    CIF,
    CHAS,
    CQMS,
    DWHS,
    DNV,
    EUROSAFE,
    FASET,
    FOREFRONT,
    GREENLIGHT,
    HIRE,
    IASSESSOR,
    IPAF,
    INTERTEK,
    LRQA,
    MSLPS,
    NASC,
    NFDC,
    NQA,
    NSAI,
    OCEAN,
    PQS,
    PREPQS,
    PS,
    SMAS,
    SGS,
    SOCOTEC,
    SCCS,
    SCSL,
    TBAB,
    URS,
    YUV
];

export {
    SSIP_SCHEME_OPERATOR_OPTIONS,
    ACCLAIM,
    ACDC,
    ACHILLES,
    ACM,
    ADVANCEDCERT,
    AJAEUROPE,
    ALCUMUS,
    ALCUMUS_SAFECONTRACTOR,
    ALTIUS,
    ARB,
    APS,
    ATLASTCERT,
    AVETTA,
    BMTRADA,
    BSI,
    BVC,
    CFA,
    CEF,
    CIF,
    CHAS,
    CQMS,
    DWHS,
    DNV,
    EUROSAFE,
    FASET,
    FOREFRONT,
    GREENLIGHT,
    HIRE,
    IASSESSOR,
    IPAF,
    INTERTEK,
    LRQA,
    MSLPS,
    NASC,
    NFDC,
    NQA,
    NSAI,
    OCEAN,
    PQS,
    PREPQS,
    PS,
    SMAS,
    SGS,
    SOCOTEC,
    SCCS,
    SCSL,
    TBAB,
    URS,
    YUV
};
