import { Tab, Tabs } from "@blueprintjs/core";
import { Link, Redirect, Switch } from "react-router-dom";
import { Routing } from "service";

import { Icon, ProtectedRoute } from "components/elements";
import AssessmentTypeInstanceRenewalsList from "./AssessmentTypeInstanceRenewalList";

const AssessmentTypeInstanceRenewals = (props: any) => {
    const {
        collection,
        load,
        filter,
        isLoading,
        defaultFilter,
        match,
        location
    } = props;

    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div className="page AssessmentTypeInstanceRenewals">
            <div className="no-styling page-wrapper">
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id='/list'>
                        <Link to={`${match.url}/list`}>
                            <Icon icon="info" />
                            Renewals
                        </Link>
                    </Tab>
                </Tabs>
                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/list`}
                            component={AssessmentTypeInstanceRenewalsList}
                            load={load}
                            collection={collection}
                            isLoading={isLoading}
                            filter={filter}
                            defaultFilter={defaultFilter}
                            exact
                        />
                        <Redirect to={`${match.url}/list`} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default AssessmentTypeInstanceRenewals;