import { Button, Checkbox } from '@blueprintjs/core';
import { findKey, get, includes, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Component } from 'react';
import DataTable from 'react-data-table-component';

import { ButtonLink, Icon } from 'components/elements';
import { HTTP, NumberFormatting } from 'service';

const rowClickTypeExclusion = [
    'nav-button',
    'external-links',
    'button',
    'callback',
    'checkbox'
];

class Table extends Component {
    static propTypes = {
        highlightOnHover: PropTypes.bool,
        striped: PropTypes.bool,
        noHeader: PropTypes.bool,
        onRowClicked: PropTypes.func
    };

    static defaultProps = {
        highlightOnHover: true,
        striped: true,
        noHeader: true,
        onRowClicked: null
    };

    constructor(props) {
        super(props);

        this.state = {
            columns: [],
            checkboxData: [],
            subHeader: false,
            subHeaderComponent: (
                <>
                    <button
                        className="clear-button underline"
                        onClick={(event) => {
                            typeof this.props.selectAll === 'function' &&
                                this.props.selectAll();
                        }}
                    >
                        Select all {this.props.collection?.count ?? 0} rows
                    </button>
                </>
            ),
        };

        this.setRows = this.setRows.bind(this);
        this.checkCheckbox = this.checkCheckbox.bind(this);

        this.sortFunction = this.sortFunction.bind(this);
        this.onSort = this.onSort.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.filter !== prevProps.filter) {
            this.setRows();
        }
    }

    componentDidMount() {
        this.setRows();
    }

    checkCheckbox = (row, column) => {
        const { collection, data } = this.props;
        let checkboxData = this.state.checkboxData;
        let showHeader = false;

        if (row.id in checkboxData) {
            delete checkboxData[row.id];
        } else {
            checkboxData[row.id] = row;
        }

        if (data.length === Object.keys(checkboxData).length) {
            showHeader = collection?.count > collection?.limit;
        }
        this.setState({ checkboxData, subHeader: showHeader }, this.setRows);

        typeof column.onChange === 'function' && column.onChange(checkboxData);
    };

    checkPageCheckbox = (column) => {
        const { collection, data } = this.props;
        let checkboxData = [];

        if (data.length === Object.keys(this.state.checkboxData).length) {
            this.setState(
                {
                    checkboxData,
                    subHeader: false,
                },
                this.setRows
            );
        } else {
            checkboxData = data.reduce((cumulative, row) => {
                let ary = [];
                if (cumulative?.id) {
                    ary[cumulative.id] = cumulative;
                } else {
                    ary = cumulative;
                }
                ary[row.id] = row;
                return ary;
            }, {});

            this.setState(
                {
                    checkboxData: checkboxData,
                    subHeader: collection?.count > collection?.limit,
                },
                this.setRows
            );
        }

        typeof column.onChange === 'function' && column.onChange(checkboxData);
    };

    setRows = () => {
        const { columns, onSort, ordering, data, ...rest } = this.props;
        let totalColumnWidths = 0;

        let stateColumns = [];

        if (Array.isArray(columns) && columns.length) {
            stateColumns = columns.filter(({ hide }) => {
                if (typeof hide === 'function') {
                    return !hide();
                }
                return !hide;
            });
        }

        stateColumns.forEach((column, index) => {
            if (column.type === 'checkbox') {
                const isChecked =
                    Object.keys(this.state.checkboxData).length === data.length;
                column.name = (
                    <>
                        <Checkbox
                            className="mb-0"
                            type="checkbox"
                            name="Select all on page"
                            checked={isChecked}
                            indeterminate={
                                !isChecked &&
                                Object.keys(this.state.checkboxData).length
                            }
                            onChange={() => this.checkPageCheckbox(column)}
                        />
                    </>
                );

                column.checkboxData = this.state.checkboxData;
                column.checkCheckbox = this.checkCheckbox;
            }
            column.cell = (row) => {
                return <Table.Cell row={row} column={column} {...rest} />;
            };
            column.sortable = !!column.property && column.sortable;
            column.selector = column.property;

            totalColumnWidths += column.grow;
        });

        this.setState({
            columns: stateColumns.map((column, index) => {
                return Object.assign({}, column, {
                    grow: (column.grow / totalColumnWidths) * 100,
                });
            }),
        });
    };

    render() {
        const { data, columns, onSort, ordering, onRowClicked, ...rest } = this.props;
        const { subHeader, subHeaderComponent } = this.state;

        let stateColumns = this.state.columns;

        stateColumns.forEach((column, index) => {
            column.cell = (row) => {
                return <Table.Cell
                    row={row}
                    column={column}
                    onRowClicked={onRowClicked}
                    {...rest}
                />;
            };
        });

        let field = ordering;
        let direction = true;
        if (ordering && ordering.substr(0, 1) === '-') {
            field = ordering.substr(1);
            direction = false;
        }

        return (
            <DataTable
                data={data}
                columns={stateColumns}
                sortServer={this.onSort ? true : false}
                onSort={this.onSort}
                defaultSortField={field}
                defaultSortAsc={direction}
                subHeaderAlign="left"
                {...rest}
                subHeader={subHeader ?? this.props.subHeader}
                subHeaderComponent={
                    subHeaderComponent ?? this.props.subHeaderComponent
                }
                onRowClicked={onRowClicked}
                pointerOnHover={typeof onRowClicked === 'function'}
            />
        );
    }

    onSort(selector, order, event) {
        if (this.props.onSort) {
            if (selector.property && order) {
                let ordering =
                    selector.property === this.props.ordering
                        ? '-' + selector.property
                        : selector.property;

                this.props.onSort({ order: ordering });
            }

            return this.props.data;
        }
    }

    sortFunction(data, selector, order) {
        if (this.props.sortFunction) {
            this.props.sortFunction(selector, order);

            return data;
        }

        return orderBy(data, selector, order);
    }
}

class Cell extends Component {
    render() {
        const { column, row, onRowClicked } = this.props;
        const { cellHide = false } = column;

        if (cellHide) {
            if (
                (typeof cellHide === 'function' && cellHide(row)) ||
                (typeof cellHide !== 'function' && Boolean(cellHide))
            ) {
                return null;
            }
        }

        if (column.render) {
            return column.render(row, column);
        }

        let content = this.getCell(column.type, row, column);

        if (!includes(rowClickTypeExclusion, column.type) && !column.disableCellClick) {
            return (
                <div onClick={(e) => onRowClicked(row, e)}>
                    {content}
                </div>
            );
        }

        return content;
    }

    getCell(type, row, column) {
        switch (type) {
            case 'nav-button':
                return this.navButtonCell(row, column);

            case 'external-link':
                return this.externalLinkCell(row, column);

            case 'date':
                return this.dateCell(row, column);

            case 'datetime':
                return this.dateTimeCell(row, column);

            case 'currency':
                return this.currencyCell(row, column);

            case 'image':
                return this.imageCell(row, column);

            case 'mapping':
                return this.mappingCell(row, column);

            case 'button':
                return this.buttonCell(row, column);

            case 'callback':
                return this.callbackCell(row, column);

            case 'yesNo':
                return this.yesNoCell(row, column);

            case 'checkbox':
                return this.checkboxCell(row, column);

            case 'text':
            default:
                return this.textCell(row, column);
        }
    }

    callbackCell(row, column) {
        return <>{column.callback(row, column)}</>;
    }

    buttonCell(row, column) {
        if (row.hide || (column.hide && column.hide(row))) {
            return null;
        }

        let text = column.text;
        if (typeof column.text === 'function') {
            text = column.text(row, column);
        }

        let intent = column.intent;
        if (typeof column.intent === 'function') {
            intent = column.intent(row, column);
        }

        let icon = column.icon;
        if (typeof column.icon === 'function') {
            icon = column.icon(row, column);
        }

        let className = column.className;

        return (
            <Button
                className={className}
                type="button"
                intent={intent || 'default'}
                onClick={(event) => {
                    column.onClick(event, row);
                    this.forceUpdate();
                }}
            >
                {icon ? <Icon icon={icon} /> : null}
                {text}
            </Button>
        );
    }

    navButtonCell(row, column) {
        const intent = column.buttonIntent || 'primary';
        const className = column.buttonClass || '';
        const text =
            (typeof column.buttonText === 'function'
                ? column.buttonText(row, column)
                : column.buttonText) || '-';

        return column.route(row, column) ? (
            <ButtonLink
                type="button"
                to={column.route(row, column)}
                intent={intent}
                className={className}
            >
                <Icon icon="eye" />
                {text}
            </ButtonLink>
        ) : null;
    }

    externalLinkCell(row, column) {
        const intent = column.buttonIntent || 'primary';
        const text = column.buttonText || '-';

        return column.route(row, column) ? (
            <Button
                type="button"
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    HTTP.stream(column.route(row, column));
                }}
                intent={intent}
            >
                <Icon icon="eye" />
                {text}
            </Button>
        ) : null;
    }

    dateCell(row, column) {
        const value = get(row, column.property);

        if (!value) {
            return <>-</>;
        }

        return <>{DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)}</>;
    }

    dateTimeCell(row, column) {
        const value = get(row, column.property);

        if (!value) {
            return <>-</>;
        }

        return (
            <>
                {DateTime.fromISO(value).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS
                )}
            </>
        );
    }

    currencyCell(row, column) {
        const value = get(row, column.property);

        return !isNaN(value) ? (
            <>{NumberFormatting.formatCurrency(value)}</>
        ) : null;
    }

    mappingCell(row, column) {
        let text;
        let value = get(row, column.property);

        if (column.mapping[value]) {
            let map = column.mapping[value];

            if (typeof map === 'string') {
                text = map;
            } else if (typeof map.prototype.hasOwnProperty === 'function') {
                text = get(map, 'value');
            }

            return this.textCell(row, column, text);
        }

        let key = findKey(column.mapping, { value: value });
        if (typeof key !== 'undefined') {
            let map = column.mapping[key];

            return this.textCell(row, column, map.value);
        }

        if (typeof value === 'boolean') {
            let key = findKey(column.mapping, { value: value.toString() });
            if (typeof key !== 'undefined') {
                let map = column.mapping[key];

                return this.textCell(row, column, map.label);
            }
        }

        return <></>;
    }

    textCell(row, column, overrideValue) {
        let displayValue = overrideValue || get(row, column.property);
        let classes = ['wrap-word'];

        if (column.colourClass) {
            classes.push(column.colourClass(row, column));
        }

        return <span className={classes.join(' ')}>{displayValue}</span>;
    }

    imageCell(row, column) {
        const file = get(row, '_links.file');
        let image = null;

        if (file) {
            image = (
                <img
                    alt={file.title}
                    title={file.title}
                    src={process.env.REACT_APP_BASE_URL + file.publicUrl}
                ></img>
            );
        }

        return <div className="image-cell">{image}</div>;
    }

    yesNoCell(row, column) {
        const value = get(row, column.property);

        switch (value) {
            case true:
                return 'Yes';
            case false:
                return 'No';
            default:
                return '';
        }
    }

    checkboxCell(row, column) {
        return (
            <>
                <Checkbox
                    className="mb-0"
                    type="checkbox"
                    name={column.property}
                    checked={row.id in column.checkboxData}
                    onChange={() => column.checkCheckbox(row, column)}
                />
            </>
        );
    }
}

Table.Cell = Cell;

export default Table;
