import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import {
    ComponentProps,
    SyntheticEvent,
    useCallback,
    useEffect,
    useReducer,
} from 'react';
import { toast } from 'react-toastify';

import { HTMLSelect } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { ClientData, Company } from 'interface';
import {
    handleSubmitIntegration,
    loadClientELBServiceProviders,
} from 'service/Integration/IntegrationService';

interface LinkElogsServiceProviderProps extends ComponentProps<any> {
    company: Company;
    client: ClientData;
    isOpen: boolean;
    onClose: Function;
    onSubmit?: Function;
}

const LinkElogsServiceProvider = (props: LinkElogsServiceProviderProps) => {
    const { client, company, isOpen, onClose, onSubmit } = props;

    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            dialogOpen: !!isOpen,
            isLoading: false,
            errorMessage: null,
            elogsSps: {
                count: 0,
                serviceProviders: [],
                serviceProviderOptions: [],
            },
            selectedElogsSp: null,
        }
    );

    const load = useCallback(async () => {
        const elogsSps = await loadClientELBServiceProviders(client.id);
        if (!elogsSps.count) {
            setState({
                errorMessage:
                    'Client does not have any ELB Service Providers, unable to continue',
            });
        } else {
            elogsSps.serviceProviderOptions = elogsSps.serviceProviders.map(
                (elogsSp: any) => {
                    return {
                        label: elogsSp.elogbooksName,
                        value: elogsSp.id,
                    };
                }
            );
        }
        setState({ elogsSps });
    }, [client?.id]);

    useEffect(() => {
        setState({ dialogOpen: isOpen });
        if (isOpen) {
            load();
        }
    }, [isOpen, load]);

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            errorMessage: null,
            elogsSps: {
                count: 0,
                serviceProviders: [],
                serviceProviderOptions: [],
            },
            selectedElogsSp: null,
            isLoading: false,
        });
        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        if (!company || !client || !state.elogsSps.count) {
            setState({
                errorMessage: 'Submission not possible. Please contact support',
            });
            toast.error('Service Provider cannot be linked');
            return;
        }
        if (!state.selectedElogsSp) {
            setState({
                errorMessage:
                    'Please pick an Elogs Service Provider from the dropdown',
            });
            toast.error('Could not submit. Please complete all fields.');
            return;
        }

        setState({ isLoading: true });
        const response = await handleSubmitIntegration(
            company.id,
            state.selectedElogsSp
        );

        typeof onSubmit === 'function' && onSubmit(response);
        setState({ isLoading: false });
        dialogClose({} as SyntheticEvent);
    };

    return (
        <Dialog
            onClose={dialogClose}
            title="Link Service Provider to Elogs"
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={state.dialogOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <div className="mb-2">
                    Please link with one of your Elogs Service Providers.
                </div>

                {!!state.elogsSps?.count && (
                    <FormGroup
                        label="Elogs Service Providers"
                        className="form-fill required"
                        labelFor="selectedElogsSp"
                    >
                        <HTMLSelect
                            id="selectedElogsSp"
                            fill={true}
                            value={state.selectedElogsSp}
                            onChange={(event: any) => {
                                setState({
                                    selectedElogsSp: event.target.value,
                                });
                            }}
                            options={[
                                NONE_SELECTED_OPTION,
                                ...state.elogsSps.serviceProviderOptions,
                            ]}
                        />
                    </FormGroup>
                )}

                {state.errorMessage && (
                    <span className="error">{state.errorMessage}</span>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className="dialog-footer-buttons">
                    <Button onClick={dialogClose}>Cancel</Button>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            type="submit"
                            intent={Intent.SUCCESS}
                            disabled={state.isLoading}
                            onClick={dialogConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default LinkElogsServiceProvider;
